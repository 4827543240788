$dark-main: #5c1a25;
$main-color: #8b2939;
$second-color: #ffc400;

@import "modules/modal";
@import "modules/form";
@import "modules/preloader";

body {
	margin: 0;
	font-family: "Red Hat Display", Helvetica, Arial, sans-serif;
}

body > section {
	padding: 70px 20px 70px 70px;
}
a {
	color: inherit;
	text-decoration: none;
}

p a {
	color: $main-color;
}

h1 {
	font-size: 83px;
	font-weight: bold;
	line-height: 2.3rem;
	margin-top: 10px;
	margin-bottom: 30px;
	text-shadow: 0 1px #000;
	@media screen and (max-width: 1000px) {
		margin-bottom: 15px;
	}
	span {
		font-size: 1.75rem;
		font-weight: medium;
	}

	@media screen and (max-width: 1000px) {
		font-size: 56px;
		span {
			font-size: 1.1rem;
		}
	}

	@media screen and (max-width: 700px) {
		font-size: 32px;
		margin-top: -10px;
		line-height: 25px;
		span {
			font-size: 1.1rem;
		}
	}
}
h2 {
	margin-top: 0;
	color: #666;
}

h3 {
	margin-top: 0;
	color: #666;
	letter-spacing: 0.5px;
	font-size: 14px;
	text-transform: uppercase;
}

p,
ul {
	line-height: 1.7;
	margin-bottom: 2.4rem;
}

div.center {
	margin: 0 auto;
	left: 0;
	right: 0;
	text-align: center;
	width: calc(100% - 110px);
}

hr {
	max-width: 55px;
	color: $main-color;
	border-color: $main-color;
	border: 0;
	border-top: 3px solid;
}

.logo.clean {
	margin-top: 30px;
	margin-left: 40px;
	width: 140px;
}

section {
	&.gallerie {
		padding: 70px 0px 70px 70px;
	}
	&#start {
		color: #fff;
		padding: 0;
		min-height: 100vh;
		background-size: cover;
		background-position: center center;
		div.center {
			top: 50%;
			position: absolute;
			transform: translateY(-50%);
		}
		.carousel {
			background: #fff;
		}
		.flickity-button:disabled {
			opacity: 0.8;
		}

		.flickity-button-icon {
			fill: $main-color !important;
		}
		.flickity-viewport {
			height: 100vh !important;

			.carousel-cell {
				height: 100%;
			}
		}
		.logo {
			max-width: 310px;
			margin-left: 240px;
			@media screen and (max-width: 1000px) {
				margin: 0 auto;
				max-width: 180px;
				margin-left: 190px;
			}

			@media screen and (max-width: 700px) {
				margin: 0 auto;
				max-width: 130px;
				margin-left: 100px;
			}
		}
	}

	.logo.clean {
		margin-left: 40px;
	}

	button,
	.btn {
		display: inline-block;
		width: 320px;
		padding: 19px;
		line-height: normal;
		text-transform: uppercase;
		color: #fff;
		background: #8b2939;
		font-size: 1.25rem;
		box-shadow: none;
		border: none;
		border-radius: 1rem;
		font-weight: 500;
		transition: 140ms ease-in;
		@media screen and (max-width: 1000px) {
			width: 180px;
			font-size: 1rem;
		}
		&:hover {
			background: $dark-main;
			&.stroke {
				border-color: $dark-main;
				color: $dark-main;
			}
		}

		&.small {
			min-height: none;
			padding: 15px;
			font-size: 1rem;
			width: auto;
		}

		&.stroke {
			background: transparent;
			border: 3px solid $main-color;
			color: $main-color;
			font-weight: bold;
		}

		&.round {
			width: 45px;
			display: inline-block !important;
			padding: 0 2px;
			height: 45px;
			min-height: initial;
			display: block;
			border-radius: 50%;
			margin: 30px auto 35px;
			transition: 140ms ease-in;
			&:hover {
				img {
					opacity: 1;
					background: $dark-main;
				}
			}
			img {
				max-height: 30px;
				margin: 11px auto;
				display: block;
				opacity: 0.8;
			}
		}
	}
}
#map {
	height: 500px;
}

footer {
	background: #333333;
	padding: 80px 0;

	.menu {
		color: #fff;
	}
}
body .comparison-slider {
	width: 100vw;
	height: 100vh;
}

.container {
	max-width: 790px;
	margin: 0 auto;
}

.bg-white {
	background: white !important;
	color: #666 !important;
}

.icon {
	width: 20px;
	height: 21px;
}

address {
	font-style: normal;
}

.limit-200 {
	max-width: 200px;
	margin: 0 auto;
}

.limit-320 {
	max-width: 320px;
	margin: 0 auto;
}

.row {
	display: -ms-grid;
	display: grid;
	justify-content: center;
	grid-column-gap: 10px;
	flex-direction: column;
}
.row.col30 {
	grid-template-columns: 1fr 1fr 1fr;

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
	}
	> div {
		text-align: center;
		padding-top: 50px;
		min-width: 350px;
		padding-bottom: 20px;
		justify-self: center;

		@media screen and (max-width: 700px) {
			min-width: initial;
			width: calc(100% - 20px);
		}
	}
}

.row.col100 {
	grid-template-columns: 1fr;
	@media screen and (max-width: 1000px) {
		margin-bottom: 20px;
	}
}

.row {
	img {
		max-width: 100%;
	}
}

.row.col50,
.col-50 {
	grid-template-columns: 1fr 1fr;
	> div:first-child,
	> a:first-child,
	> fieldset:first-child {
		-ms-grid-column: 1;
	}
	> div:last-child,
	> a:last-child,
	> fieldset:last-child {
		-ms-grid-column: 2;
	}
	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr;

		> div:first-child,
		> a:first-child,
		> fieldset:first-child {
			-ms-grid-column: 1;
			-ms-grid-row: 1;
		}
		> div:last-child,
		> a:last-child,
		> fieldset:last-child {
			-ms-grid-column: 1;
			-ms-grid-row: 2;
		}
	}

	@media screen and (max-width: 1000px) {
		&.same_as_desktop {
			grid-template-columns: 1fr 1fr;
		}
	}
}

.row.col80 {
	grid-template-columns: 4fr 2fr;
	grid-column-gap: 30px;
	// align-items: start;
	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr;
	}
}

.col30 > div {
	max-width: 350px;
}
.col30 > div:nth-child(1) {
	-ms-grid-column: 1;
	@media screen and (max-width: 1000px) {
		-ms-grid-column: 1;
		-ms-grid-row: 1;
	}
}
.col30 > div:nth-child(2) {
	-ms-grid-column: 2;
	@media screen and (max-width: 1000px) {
		-ms-grid-column: 1;
		-ms-grid-row: 2;
	}
}
.col30 > div:nth-child(3) {
	-ms-grid-column: 3;
	@media screen and (max-width: 1000px) {
		-ms-grid-column: 1;
		-ms-grid-row: 3;
	}
}

.menu {
	text-align: center;
	> ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline;
			padding: 25px;
		}
	}
}

.border {
	border-bottom: 7px solid $main-color;
}

// start leistung grid
#leistungen {
	padding: 70px 20px 70px 70px;
}
.flickity-page-dots {
	bottom: 40px !important;
}
.flickity-button-icon {
	fill: white !important;
	width: 80% !important;
	height: 80% !important;
}

.flickity-button {
	background: none !important;
}

.text a {
	font-weight: bold;
	color: $second-color;
	&:hover {
		color: white;
	}
}
p {
	color: #666;
}
.grid {
	.bg-main h2 {
		color: #fff;
	}
	.text {
		background: $main-color;
	}
	article {
		padding: 0 20px;
	}

	.bg-main p {
		color: #fff;
	}
	max-width: 790px;
	margin: 0 auto;
	background: $main-color;
	color: #fff;
	hr {
		border-color: #ffc400;
		text-align: left;
		margin-left: 0;
	}
	.col50 {
		grid-column-gap: 0;
	}
	&.col100:nth-child(even) > .col50 > div {
		&:first-child {
			order: 1;
			-ms-grid-column: 1;
		}
		&:last-child {
			order: 2;
			-ms-grid-column: 2;
		}

		@media screen and (max-width: 1000px) {
			&:first-child {
				order: 1;
				-ms-grid-column: 1;
				-ms-grid-row: 1;
			}
			&:last-child {
				order: 2;
				-ms-grid-column: 1;
				-ms-grid-row: 2;
			}
		}
	}
	&.col100:nth-child(odd) > .col50 > div {
		&:first-child {
			order: 2;
			-ms-grid-column: 2;
		}
		&:last-child {
			order: 1;
			-ms-grid-column: 1;
		}

		@media screen and (max-width: 1000px) {
			&:first-child {
				order: 1;
				-ms-grid-column: 1;
				-ms-grid-row: 2;
			}
			&:last-child {
				order: 2;
				-ms-grid-column: 1;
				-ms-grid-row: 1;
			}
		}
	}

	.text {
		min-width: 355px;
		padding: 70px 20px 30px;
		@media screen and (max-width: 1000px) {
			padding: 50px 20px;
			min-width: initial;
		}

		@media screen and (max-width: 700px) {
			min-width: initial;
		}
	}

	.cover {
		width: 100%;
		height: 100%;
		background-size: cover;
		@media screen and (max-width: 1000px) {
			height: 270px;
		}
		a {
			opacity: 0;
			display: block;
			position: relative;
			left: 0;
			right: 0;
			height: 100%;
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
			padding-top: 145px;
			font-size: 1.25rem;
			box-sizing: border-box;
			background: rgba(139, 41, 57, 0.7);
			transition: 140ms ease-in;
			@media screen and (max-width: 1000px) {
				padding-top: 200px;
				box-sizing: border-box;
			}
		}

		&:hover a {
			opacity: 1;
		}
	}
}

.sidemenu {
	transform: rotate(-90deg) translateX(-110px);
	text-align: right;
	position: absolute;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold;
	color: #666;
	left: -30px;
	left: -830px;
	right: 0;
	margin: 0 auto;
	width: 225px;
	@media screen and (max-width: 1000px) {
		left: -50px;
		right: auto;
	}
}

#ueberuns {
	padding: 0 20px 70px 70px;
	max-width: 790px;
	margin: 0 auto;

	.col80 {
		> div:last-child {
			-ms-grid-column: 2;
		}
		> div:first-child {
			position: relative;
			-ms-grid-column: 1;
			hr {
				position: absolute;
				right: -7px;
				border: 4px solid $second-color;
				height: 100px;
				top: calc(50% - 50px);
			}
		}
	}
}

.carousel,
.lazy-carousel .carousel-cell {
	background: #fff !important;
}

.flickity-page-dots .dot {
	background: $main-color !important;
	border-radius: 0 !important;
}

.lazy-carousel .flickity-page-dots {
	bottom: -25px !important;
}

.lazy-carousel .flickity-button-icon {
	fill: #fff !important;
}

#contact .col-50,
#contact .col-33 {
	grid-column-gap: 40px;
}

#contact .col-50 > fieldset {
	&:first-child {
		-ms-grid-column: 1;
		-ms-grid-row: 1;
	}
	&:last-child {
		-ms-grid-column: 2;
		-ms-grid-row: 1;
	}
}

#contact .col-50 {
	display: -ms-grid;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

#contact .col-33 {
	display: -ms-grid;
	display: grid;
	grid-template-columns: auto auto auto;
}

@media screen and (max-width: 700px) {
	#contact .col-50 {
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
	}
	#contact .col-50 > fieldset {
		&:first-child {
			-ms-grid-column: 1;
			rid-column: 1;
			-ms-grid-row: 1;
		}
		&:last-child {
			-ms-grid-column: 1;
			-ms-grid-row: 2;
		}
	}
}

.red {
	color: $main-color;
}

.icon-scroll,
.icon-scroll:before {
	position: absolute;
	left: 50%;
}

.icon-scroll {
	width: 40px;
	height: 60px;
	margin-left: -20px;
	bottom: 20px;
	margin-top: -35px;
	box-shadow: inset 0 0 0 2px #fff;
	border-radius: 25px;
	transition: transform 300ms ease-in, opacity 300ms ease-in;
	&.hide {
		transform: translateY(100px);
		opacity: 0;
	}
}

.icon-scroll:before {
	content: "";
	width: 8px;
	height: 8px;
	background: #fff;
	margin-left: -4px;
	top: 8px;
	border-radius: 4px;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: scroll;
}

@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(46px);
	}
}

@media screen and (max-width: 700px) {
	.lazy-carousel .carousel-cell {
		width: 100% !important;
	}
	.carousel-cell img {
		width: 100%;
		height: auto;
	}
}

.popup {
	right: 10px;
	float: right;
	margin-right: 10px;
	top: 10px;
	position: absolute;
	z-index: 10;
	width: 320px;
	background: rgba(255, 255, 255, 0.7);
	padding: 20px;

	.background-icon {
		position: absolute;
		bottom: 20px;
		width: 30px;
		left: 20px;
		opacity: 0.5;
	}
	@media screen and (max-width: 700px) {
		width: 100%;
		float: none;
		top: 0;
		right: 0;
		left: 0;
		box-sizing: border-box;
	}
}

.lazy-carousel .flickity-button-icon {
	fill: $main-color !important;
}
