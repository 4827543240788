.cc-message {
  color: #fff;
}

a.cc-link {
  color: #fff !important;
}

.cc-btn.cc-deny {
  background: transparent;
  min-height: 48px;
  color: #fff;
  margin-right: 20px;
  border-radius: 24px;
}
.cc-btn {
  font-weight: bold;
  outline: none;
  line-height: 24px;
  padding: 10px 15px 12px;
}

.cc-btn.cc-allow {
  margin-right: 30px;
  font-weight: 600;
  padding: 13px 30px;
  background: #8b2939;
  border-radius: 24px;
  outline: none;
  border-style: solid;
  color: white;
  font-size: 1.25rem;
  margin: 15px auto;
  padding: 10px 35px 12px;
  line-height: 1;
  min-height: 48px;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  border: 0;
  text-decoration: none;
  text-align: center;
  transition: 140ms ease-in background-image;
  user-select: none;
  max-width: 300px !important;
  transition: 140ms background-image ease-in;
  font-family: "Neue Haas Unica W01", "Helvetica Neue", Arial, sans-serif;
}

.cc-message {
  display: block;
  max-width: 760px;
  float: left;
  margin-right: 70px;
}

.cc-window {
  bottom: 0;
  position: fixed;
  z-index: 100000000000;
  padding: 15px;
  background: #333333;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: block;
}

.cc-compliance {
  display: block;
  width: 300px;
  margin-top: 15px;
  float: right;
}

.cc-revoke {
  display: none;
}

.cc-link {
  font-weight: Bold;
}
@media screen and (max-width: 1070px) {
  .cc-message,
  .cc-compliance {
    float: none;
    margin-top: 25px;
    margin: 15px;
  }

  .cc-compliance {
    margin-top: 25px;
  }
}
