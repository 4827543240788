/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus {
	outline: none;
}

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
	position: absolute;
	background: hsla(0, 0%, 100%, 0.75);
	border: none;
	color: #333;
}

.flickity-button:hover {
	background: white;
	cursor: pointer;
}

.flickity-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
	opacity: 0.6;
}

.flickity-button:disabled {
	opacity: 0.3;
	cursor: auto;
	/* prevent disabled button from capturing pointer up event. #716 */
	pointer-events: none;
}

.flickity-button-icon {
	fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
	top: 50%;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	/* vertically center */
	transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
	left: 10px;
}
.flickity-prev-next-button.next {
	right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1;
}

.flickity-rtl .flickity-page-dots {
	direction: rtl;
}

.flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: 0.25;
	cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
	opacity: 1;
}

.carousel {
	background: #eee;
}

.carousel-cell {
	margin-right: 20px;
	overflow: hidden;
}

.carousel-cell img {
	display: block;
	height: 200px;
}

@media screen and (min-width: 768px) {
	.carousel-cell img {
		height: 400px;
	}
}

.lazy-carousel {
	.carousel {
		background: #fafafa;
	}

	.carousel-cell {
		width: 66%;
		height: 400px;
		margin-right: 10px;
		background: #333;
	}

	.carousel-cell-image {
		display: block;
		max-height: 100%;
		margin: 0 auto;
		max-width: 100%;
		opacity: 0;
		-webkit-transition: opacity 0.4s;
		transition: opacity 0.4s;
	}

	/* fade in lazy loaded image */
	.carousel-cell-image.flickity-lazyloaded,
	.carousel-cell-image.flickity-lazyerror {
		opacity: 1;
	}
}

.carousel {
	background: #222;
}

.carousel-cell {
	width: 100%;
	height: 300px;
	margin-right: 10px;
	/* center images in cells with flexbox */
	display: flex;
	align-items: center;
	justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
	height: 100%;
}

.carousel-cell-image {
	display: block;
	max-height: 100%;
}

.carousel.is-fullscreen .carousel-cell-image {
	max-width: 100%;
}

.three-images {
	.carousel {
		background: #fafafa;
	}

	.carousel-cell {
		width: 33%;
		height: 500px;
		margin-right: 10px;
		background: #8c8;
		border-radius: 5px;
		counter-increment: carousel-cell;
	}

	/* cell number */
	.carousel-cell:before {
		display: block;
		text-align: center;
		content: counter(carousel-cell);
		line-height: 200px;
		font-size: 80px;
		color: white;
	}
}
