.container {
  max-width: calc(100% - 20px);
}
input,
textarea {
  font-size: 16px !important;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Open Sans", Helvetica, Arial, sans-serif;
}

#contact h3 {
  color: #f96;
  display: block;
  font-size: 30px;
  font-weight: 400;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}
#contact fieldset {
  position: relative;
}

#contact {
  transition: all 300ms ease-in;
}

#contact.closed {
  opacity: 0.5;
  max-height: 0px;
  overflow: hidden;
  pointer-events: none;
  #contact-submit {
    display: none;
  }
}

.modal .closed p.msg_success {
  font-weight: bold;
  margin-top: 110px;
  color: $second-color;
}

#contact fieldset span {
  position: absolute;
  bottom: 14px;
  color: $main-color;
  font-size: 12px;
  right: 0;
  pointer-events: none;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
  background: #fff;
  margin: 0 0 5px;
  padding: 10px 0;
}

#contact a {
  color: $main-color;
  font-weight: bold;
}

#contact label[for="privacy"] {
  font-size: 12px;
}

#contact textarea {
  height: 40px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  border: none;
  background: $main-color;
  color: #fff;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: $dark-main;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border-bottom: 1px solid #999;
}

::-webkit-input-placeholder {
  color: #888;
}
:-moz-placeholder {
  color: #888;
}
::-moz-placeholder {
  color: #888;
}
:-ms-input-placeholder {
  color: #888;
}

fieldset {
  transition: opactiy 300ms ease-in;
}

.loading fieldset {
  opacity: 0.5;
}
