.loader {
	border: 5px solid $dark-main; /* Light grey */
	border-top: 5px solid $second-color; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader {
	position: absolute;
	z-index: 10000;
	top: 200px;
	margin: 0 auto;
	left: 0;
	right: 0;
	width: 30px;
	height: 30px;
	display: none;
}

.loading .loader {
	display: block;
}
