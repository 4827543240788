.image-splite img {
  width: 100%;
  vertical-align: middle;
}

.image-spliter,
img,
.mover {
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.image-spliter {
  position: relative;
}

.img-left {
  position: absolute;
  top: 0;
  left: 0;
}

.mover {
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}
.mover:before,
.mover:after {
  position: absolute;
  left: 50%;
  content: "";
  background: #fff;
  cursor: -webkit-grab;
  cursor: grab;
}

.mover:before {
  top: 0;
  transform: translateX(-50%);
  width: 1px;
  height: 100%;
}
.mover:after {
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 33%;
  border-radius: 5px;
}
