.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 1000000000;
}

.overlay .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.modal {
  margin: 100px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  max-width: 600px;
  border-radius: 6px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal h2 {
  margin-top: 0;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.modal .content {
  overflow: auto;
  min-height: 360px;
}

.modal p {
  margin: 0 0 1em;
  text-align: center;
}

.modal p:last-child {
  margin: 0;
}

a.button {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  display: block;
  font-weight: 300;
  height: 50px;
  padding: 15px;
  text-align: center;
  border-radius: 6px;
  margin: 40px auto;
  max-width: 200px;
  opacity: 1;
  color: #333;
  text-decoration: none;
  transition: 0.3s box-shadow ease;
  -webkit-transition: 0.3s box-shadow ease;
  transform: translateY(0px);
  text-shadow: 0 0 0;
}

a.button:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}

em {
  font-size: 12px;
}
